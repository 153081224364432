/*!
 * Start Bootstrap - Logo Nav HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

html {
    position: relative;
    min-height: 100%;
}
body {
    /* Margin bottom by footer height */
    margin-bottom: 30px;
    /* Required padding for .navbar-fixed-top. Change if height of navigation changes. */
    padding-top: 70px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 30px;
    background-color: #ffffff;
}

.navbar-fixed-top .nav {
    padding: 15px 0;
}

.navbar-fixed-top .navbar-brand {
    padding: 0 15px;
}

#calendar {
    margin: 0 auto;
}

.box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 25px;
    display: block;
    margin-top: 10px;
}
.box-icon {
    background-color: #57a544;
    border-radius: 50%;
    display: table;
    height: 100px;
    margin: 0 auto;
    width: 100px;
    margin-top: -61px;
}
.box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
.info > a {
    background-color: #03a9f4;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    transition: all 0.5s ease 0s;
}
.info > a:hover {
    background-color: #0288d1;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    transition: all 0.5s ease 0s;
}

.partner-copy-block {
    padding-top: 27px;
    text-align: center;
}

.partner-copy {
    font-size: 22px;
    padding: 4px 12px;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #ccc;
    position: relative;
    cursor: pointer;
}

.partner-copy i {
    padding-left: 5px;
}

.partner-copy-arrow-left {
    border-color: #000 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    position: absolute;
    top: 41%;
    height: 0;
    left: 0;
    width: 0;
    transform: rotate(90deg);
    margin-left: 5px;
}

.partner-copy-arrow-up {
    display: none;
    border-color: #000 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    position: absolute;
    top: 41%;
    height: 0;
    left: 0;
    width: 0;
    transform: rotate(180deg);
    margin-left: 5px;
}

a.nav-link {
    color: #aaa!important;
    font-weight: 900;
}

.nav-tabs>li.active>a, 
.nav-tabs>li.active>a:focus, 
.nav-tabs>li.active>a:hover {
    color: #000!important;
    font-weight: 900;
}

#myTab {
    background-color: #eee!important;
}

.documents a {
    color: #333!important;
}

.document-bg-grey {
    background-color: #eee;
}

#file_id {
    padding: 0!important;
    background-color: unset!important;
    border: unset!important;
    box-shadow: unset!important;
}

.documenst-title > div,
.comment {
    font-weight: 700;
}

.text-break {
    word-break: break-all;
}

.delete-document {
    background-color: #eee!important;
    color: #f00;
    margin-top: 7px;
    font-size: 22px;
}

.delete-program {
    background-color: #fff!important;
    color: #f00;
    margin-top: 7px;
    font-size: 22px;
}

.fc-slats {
    z-index: unset!important;
}

.fc-slats table tr:nth-child(odd) td {
    background-color: #f9f9f9;
}

.fc-bg table tr td {
    opacity: 0.5;
}

/****  Margin  ****/
.m-auto {margin:auto;}
.m-0 {margin:0 !important;}
.m-5 {margin:5px !important;}
.m-10 {margin:10px !important;}
.m-20 {margin:20px !important;}

/****  Margin Top  ****/
.m-t-0 {margin-top:0px !important;}
.m-t-5{margin-top:5px !important; }
.m-t-10{margin-top:10px !important;}
.m-t-20{margin-top:20px !important;}
.m-t-30{margin-top:30px !important;}
.m-t-40{margin-top:40px !important;}
.m-t-60{margin-top:60px !important;}

/****  Margin Bottom  ****/
.m-b-0 {margin-bottom:0px !important;}
.m-b-5 {margin-bottom:5px !important;}
.m-b-6 {margin-bottom:6px !important;}
.m-b-10 {margin-bottom:10px !important;}
.m-b-12 {margin-bottom:12px !important;}
.m-b-15 {margin-bottom:15px !important;}
.m-b-20 {margin-bottom:20px !important;}
.m-b-30 {margin-bottom:30px !important;}
.m-b-40 {margin-bottom:40px !important;}
.m-b-60 {margin-bottom:60px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-140{margin-bottom:140px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-245 {margin-bottom:245px !important;}
.m-b-245 {margin-bottom:245px !important;}
.m-b-m30 {margin-bottom:-30px !important;}
.m-b-m50 {margin-bottom:-50px !important;}

/****  Margin Left  ****/
.m-l-5 {margin-left:5px !important;}
.m-l-10 {margin-left:10px !important;}
.m-l-20 {margin-left:20px !important;}
.m-l-30 {margin-left:30px !important;}
.m-l-60 {margin-left:60px !important;}

/****  Margin Right  ****/
.m-r-5 {margin-right:5px !important;}
.m-r-10 {margin-right:10px !important;}
.m-r-20 {margin-right:20px !important;}
.m-r-30 {margin-right:30px !important;}
.m-r-60 {margin-right:60px !important;}

/****  Padding  ****/
.p-0 {padding:0 !important;}
.p-5 {padding:5px !important;}
.p-10 {padding:10px !important;}
.p-15 {padding:15px !important;}
.p-20 {padding:20px !important;}
.p-30 {padding:30px !important;}
.p-40 {padding:40px !important;}

/****  Padding Top  ****/
.p-t-0 {padding-top:0 !important;}
.p-t-10 {padding-top:10px !important;}
.p-t-20 {padding-top:20px !important;}
.p-t-24 {padding-top:24px !important;}

/****  Padding Bottom  ****/
.p-b-0 {padding-bottom:0 !important;}
.p-b-10 {padding-bottom:10px !important;}
.p-b-20 {padding-bottom:10px !important;}
.p-b-30 {padding-bottom:30px !important;}

/****  Padding Left  ****/
.p-l-5 {padding-left:5px !important;}
.p-l-10 {padding-left:10px !important;}
.p-l-20 {padding-left:20px !important;}
.p-l-30 {padding-left:30px !important;}
.p-l-40 {padding-left:40px !important;}

/* Padding Right  ****/
.p-r-0 { padding-right:0px!important}
.p-r-5 {padding-right:5px !important;}
.p-r-10 {padding-right:10px !important;}
.p-r-20 {padding-right:20px !important;}
.p-r-30 {padding-right:30px !important;}

/****  Top  ****/
.t-0 {top:0; }
.t-5 {top:5px; }
.t-10 {top:10px; }
.t-15 {top:15px; }

/****  Bottom  ****/
.b-0 {bottom:0; }
.b-5 {bottom:5px; }
.b-10 {bottom:10px; }
.b-15 {bottom:15px; }

/****  Left  ****/
.l-0 {left:0; }
.l-5 {left:5px; }
.l-10 {left:10px; }
.l-15 {left:15px; }

/****  Right  ****/
.r-0 {right:0; }
.r-5 {right:5px; }
.r-10 {right:10px; }
.r-15 {right:15px; }

.font-weight-700 {
    font-weight: 700;
}
/****  Forms Validation ****/
span.parsley-required, span.parsley-type, span.parsley-remote{display:block; margin: -25px 0 20px 0; width: 100%; color: #A94442}
.parsley-errors-list {list-style-type: none; width: 100%; font-size:14px;color:#000;padding: 5px 10px 0 0; padding-left: 0px;}
.parsley-customError {
    background-color:#d9534f;
    border-radius: 0.25em;
    color: #fff;
    display: inline;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    padding: 0.2em 0.6em 0.3em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.legend div {
    margin-left:15px;
    width:20px;
    border:1px solid #dddddd;
    display:inline-block;
}

.legend div:first-child {
    margin-left: 0px;
}

.legend a {
    text-decoration: none;
    color: #000000;
}

a.selected-venue {
    font-weight: bold;
}

div#loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

div.tooltip-inner {
    min-width: 250px;
    max-width: 500px;
    background-color: rgb(79, 112, 188);
    box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
}

div.tooltip-arrow {
    color: rgb(79, 112, 188);
}



.stripe {
    background-image: repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(255,255,255,0.2) 5px, rgba(255,255,255,0.2) 12px);
}

table.sortable tbody > tr {
    cursor: pointer;
}

table.sortable i.sort-handler {
    display:none;
}

table.sortable tr:hover i.sort-handler {
    display:inline; cursor: all-scroll;
}

body.dragging, body.dragging * {
    cursor: move !important;
}

.dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
}

table.sortable tr.placeholder {
    position: relative;
}
table.sortable tr.placeholder:before {
    position: absolute;
}

@media(min-width:768px) {
    body {
        padding-top: 100px; /* Required padding for .navbar-fixed-top. Change if height of navigation changes. */
    }

    .navbar-fixed-top .navbar-brand {
        padding: 15px 0;
    }

    .modal-dialog {
        width: 1000px!important;
    }
}

@media (max-width: 992px) {
    .partner-copy-block {
        padding-bottom: 20px;
    }

    .partner-copy-arrow-up {
        display: block;
    }

    .partner-copy-arrow-left {
        display: none;
    }

    .team-name-padding {
        padding-right: 15px!important;
    }

    .del-x {
        text-align: center;
    }
}

.tooltip-table td {
    vertical-align: middle;
}

.summary-block {
    padding: 15px;
}

.summary-block h3 {
    font-weight: 700;
}

.summary-block .period {
    font-weight: 700;
    padding-right: 10px;
}

.summary-block .start {
    font-weight: 700;
}

.summary-block .start_time {
    border: 1px solid #ddd;
    padding: 8px;
}

.summary-block .start-time {
    display: inline-block!important;
    padding: 8px;
    border: 1px solid #ddd;
}

.summary-price {
    background-color: #5bc0de;
    color: #fff;
    padding: 10px;
}

.d-flex {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ticket-row {
    padding: 10px;
}

.tickets .ticket-row:nth-child(odd) {
    background-color: rgba(0,0,0,.05);;
}

.tickets .ticket-row:nth-child(even) {
    background-color: rgba(0,0,0,.1);;
}

.extra-box {
    height: 100px;
    border: 1px solid #ddd;
    padding: 5px;
}

.extra-field {
    display: flex;
    align-items: unset;
    justify-content: flex-end;
}